import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import {
  ResourceCard,
  SizingCard,
  ProductsHeader,
  ProductDetails,
  SeoData,
  Instructions,
} from "components";
import { H2, Container, FlexGrid, Grid } from "styles";
import { motion } from "framer-motion";

const ProductsPage = ({ location, data }) => {
  const { products, instructionVideos, instructions } =
    data.allMarkdownRemark.edges[0].node.frontmatter;
  const [selected, setSelected] = useState(products[0]);

  const changeProduct = (product) => {
    setSelected(product);
  };

  useEffect(() => {
    if (location?.state?.product) {
      setSelected(products[location.state.product]);
    }
  }, [location, products]);

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.25, duration: 0.75 }}
    >
      <SeoData
        pageSpecificTitle="Products"
        pageSpecificDescription="AeroChamber® Plus® Flow-Vu® AeroChamber® Plus® Flow-Vu® is available in various mask sizes – find out more or contact Teva: T: +44 207 540 7117 or E: Medinfo:@tevauk.com"
      />
      <ProductsHeader changeProduct={changeProduct} products={products} />
      <ProductDetails product={selected} videos={instructionVideos} />

      <Container>
        <H2 id="howto" centered style={{ padding: "0 1rem" }}>
          How To Use It
        </H2>
        <Instructions instructions={instructions} />
      </Container>
      <Container>
        <H2 centered style={{ padding: "0 1rem" }}>
          Important Features
        </H2>
        <FlexGrid>
          <ResourceCard
            image={data.featureFlap}
            description="<p>Flap moves when the patient inhales so caregiver can visually monitor if the patient is inhaling correctly.</p>"
          />
          <ResourceCard
            image={data.featureAdapter}
            description="<p>Universal Adapter that fits
            the most commonly prescribed
            metered dose inhalers.</p>"
          />
          <ResourceCard
            svg="safety"
            description="<p>AeroChamber Plus® Flow-Vu® is dishwasher safe on the top rack at temperatures up to 158°F (70°C).</p>"
          />
        </FlexGrid>
      </Container>
      <Container>
        <Grid>
          <SizingCard />
        </Grid>
      </Container>
    </motion.main>
  );
};

export const query = graphql`
  query ProductsQuery {
    allMarkdownRemark(filter: { frontmatter: { title: { eq: "products" } } }) {
      edges {
        node {
          id
          frontmatter {
            products {
              description
              id
              hasMask
              link
              instructionsLink
              images {
                image {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              shortName
              ageRange
              name
              mainImg {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              thumb1 {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              thumb2 {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              thumb3 {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            instructionVideos {
              title
              type
              description
              jobCode
              thumbnail {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              link
              embed
            }
            instructions {
              title
              steps {
                title
                description
                thumbnail {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
    featureAdapter: file(relativePath: { eq: "feature-adapter.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    featureFlap: file(relativePath: { eq: "feature-flap.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`;

export default ProductsPage;
